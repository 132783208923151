import React from 'react'
import Sectionimage from '../../Sectionimage'
import imagelol from '../../../images/lol.jpg'

const Lol = () => {
  return (
    <div className='section'>
        <Sectionimage src={imagelol}/>
    </div>
  )
}

export default Lol