import React from 'react'
import Playercards from '../../Players/Playercards/Playercards'

const Csgo = () => {
  return (
    <div className='section'>
      <Playercards />
    </div>
  )
}

export default Csgo