import React from 'react'
import Sectionimage from '../../Sectionimage'
import imagedota2 from '../../../images/dota2.jpg'

const Dota2 = () => {
  return (
    <div className='section'>
        <Sectionimage src={imagedota2}/>
    </div>
  )
}

export default Dota2