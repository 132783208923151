import React from 'react'
import Sectionimage from '../../Sectionimage'
import imagewot from '../../../images/wot.jpg'

const Wot = () => {
  return (
    <div className='section'>
        <Sectionimage src={imagewot}/>
    </div>
  )
}

export default Wot